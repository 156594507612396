export default defineNuxtRouteMiddleware(async (to, from) => {
    // const { userStateReady } = useAuthStore();
    // if (process.client) {
    //     const { $getAuth, $router } = useNuxtApp();
    //     const auth: Auth = $getAuth as Auth;
    //     // auth.authStateReady().then(() =>
    //     //     // console.log("We're here.")
    //     // );
    // }
});
